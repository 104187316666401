var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "page-header-wrapper",
    {
      scopedSlots: _vm._u([
        {
          key: "content",
          fn: function () {
            return [
              _c("div", { staticClass: "page-header-content" }, [
                _c(
                  "div",
                  { staticClass: "avatar" },
                  [
                    _c("a-avatar", {
                      attrs: { size: "large", src: _vm.avatar },
                    }),
                  ],
                  1
                ),
                _c("div", { staticClass: "content" }, [
                  _c("div", { staticClass: "content-title" }, [
                    _vm._v(_vm._s(_vm.timeFix) + "，" + _vm._s(_vm.nickname)),
                    _c("span", { staticClass: "welcome-text" }),
                  ]),
                  _c("div", [
                    _vm._v(
                      _vm._s(_vm.postGroup) +
                        " | " +
                        _vm._s(_vm.user.dept.deptName)
                    ),
                  ]),
                ]),
              ]),
            ]
          },
          proxy: true,
        },
        {
          key: "extraContent",
          fn: function () {
            return undefined
          },
          proxy: true,
        },
      ]),
    },
    [
      _c(
        "div",
        [
          _c(
            "a-row",
            { attrs: { gutter: 24 } },
            [
              _c(
                "a-col",
                { attrs: { xl: 24, lg: 24, md: 24, sm: 24, xs: 24 } },
                [
                  _c(
                    "a-card",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { title: "物联网管理平台 简介" },
                    },
                    [
                      _c(
                        "blockquote",
                        _vm._l(_vm.$project.homeIntroduction, function (item) {
                          return _c("p", { key: item }, [_vm._v(_vm._s(item))])
                        }),
                        0
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }